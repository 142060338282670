import { useState, useEffect } from 'react';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../utils/firebase';
import { useAuth } from './useAuth';

export type Theme = 'light' | 'dark';

export const useTheme = () => {
  const [theme, setTheme] = useState<Theme>(() => {
    // Initialize from DOM on mount or default to light
    return (document.documentElement.getAttribute('data-theme') as Theme) || 'light';
  });
  const { user, loading } = useAuth();

  const applyTheme = (newTheme: Theme) => {
    // Apply to DOM first
    document.documentElement.setAttribute('data-theme', newTheme);
    // Then update state
    setTheme(newTheme);
  };

  useEffect(() => {
    const loadTheme = async () => {
      try {
        if (loading) return;

        if (!user) {
          // For non-logged in users, always use light theme
          applyTheme('light');
          return;
        }

        // For logged-in users, get theme from database
        const userRef = doc(db, 'User_Profile', user.uid);
        const userSnap = await getDoc(userRef);
        
        if (userSnap.exists() && userSnap.data().theme) {
          const savedTheme = userSnap.data().theme as Theme;
          applyTheme(savedTheme);
        } else {
          // Default to light theme for new users
          applyTheme('light');
          
          if (userSnap.exists()) {
            await updateDoc(userRef, { theme: 'light' });
          }
        }
      } catch (error) {
        console.error('Error loading theme:', error);
        applyTheme('light');
      }
    };

    loadTheme();
  }, [user, loading]);

  const toggleTheme = async () => {
    try {
      // Only allow toggling if user is logged in
      if (!user) {
        console.log('Theme toggling is only available for logged-in users');
        return theme;
      }

      // Get the current DOM theme to ensure we're in sync
      const currentTheme = document.documentElement.getAttribute('data-theme') as Theme;
      const newTheme: Theme = currentTheme === 'light' ? 'dark' : 'light';
      
      // Apply to DOM first
      document.documentElement.setAttribute('data-theme', newTheme);
      // Then update state
      setTheme(newTheme);

      if (user.uid) {
        const userRef = doc(db, 'User_Profile', user.uid);
        await updateDoc(userRef, { theme: newTheme });
      }
      
      return newTheme;
    } catch (error) {
      console.error('Error toggling theme:', error);
      return theme;
    }
  };

  return { 
    theme, 
    toggleTheme,
    // Return whether theme toggling is available
    canToggleTheme: !!user 
  };
};